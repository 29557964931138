.header {
  position: absolute;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  width: 100vw;
  background-color: transparent;
  /* box-shadow: 0px -16px 48px rgba(0, 0, 0, 0.2); */
  box-sizing: border-box;
  top: 0;
  justify-content: space-between;
}

.header .logo-container {
  display: flex;
  align-items: center;
}
