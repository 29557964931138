.content {
    margin: 12px;
    height: 100%;
}

.content-middle{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}